@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'DMSans';
    src: url('/themes/nomad-spark/fonts/dmsans-regular.woff2') format('woff2');
    font-weight: 400;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('/themes/nomad-spark/fonts/dmsans-regular-italic.woff2')
      format('woff2');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'DMSans';
    src: url('/themes/nomad-spark/fonts/dmsans-medium.woff2') format('woff2');
    font-display: block;
    font-weight: 500;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('/themes/nomad-spark/fonts/dmsans-medium-italic.woff2')
      format('woff2');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'DMSans';
    src: url('/themes/nomad-spark/fonts/dmsans-bold.woff2') format('woff2');
    font-weight: 300;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('/themes/nomad-spark/fonts/dmsans-bold-italic.woff2')
      format('woff2');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'RomekRounded';
    src: url('/themes/nomad-spark/fonts/romek-rounded-light.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'RomekRounded';
    src: url('/themes/nomad-spark/fonts/romek-rounded-light.woff2')
      format('woff2');
    font-weight: 300;
  }
  @font-face {
    font-family: 'RomekRounded';
    src: url('/themes/nomad-spark/fonts/romek-rounded-light-italic.woff2')
      format('woff2');
    font-weight: 300;
    font-style: italic;
  }

  svg {
    vertical-align: text-top;
  }

  .layout {
    @apply mx-auto max-w-7xl px-4 sm:px-6;
  }
}

@layer components {
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
}

@layer utilities {
  .animate-pause {
    animation-play-state: paused;
  }
}
